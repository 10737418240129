

import { createRouter, createWebHashHistory } from 'vue-router';
const routerHistory = createWebHashHistory()

const routes = [
    {
        path: '/',
        redirect: '/p_index'
    },
    {
        path: '/p_index',
        name: 'p_index',
        component: () => import('@/components/pc/index'),
        meta: {
            title: '绿蜜蜂旧衣服回收'
        }
    },
    {
        path: '/p_retrieve',
        name: 'p_retrieve',
        component: () => import('@/components/pc/retrieve'),
        meta: {
            title: '预约回收'
        }
    },
    {
        path: '/p_new',
        name: 'p_new',
        component: () => import('@/components/pc/new'),
        meta: {
            title: '平台资讯'
        }
    },
    {
        path: '/p_newDetail',
        name: 'p_newDetail',
        component: () => import('@/components/pc/newDetail'),
        meta: {
            title: '资讯详情'
        }
    },
    {
        path: '/p_aboutUs',
        name: 'p_aboutUs',
        component: () => import('@/components/pc/aboutUs'),
        meta: {
            title: '关于我们'
        }
    },
    {
        path: '/p_login',
        name: 'p_login',
        component: () => import('@/components/pc/login'),
        meta: {
            showFooter: true,
            title: '用户登录'
        }
    },

]
const routesM = [
    {
        path: '/',
        redirect: '/m_index'
    },
    {
        path: '/m_index',
        name: 'm_index',
        component: () => import('@/components/mobile/index'),
        meta: {
            title: '旧衣服回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/m_retrieve',
        name: 'm_retrieve',
        component: () => import('@/components/mobile/retrieve'),
        meta: {
            title: '旧衣服回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/m_address',
        name: 'm_address',
        component: () => import('@/components/mobile/address'),
        meta: {
            title: '旧衣服回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/m_addAddress',
        name: 'm_addAddress',
        component: () => import('@/components/mobile/addAddress'),
        meta: {
            title: '旧衣服回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/m_gift',
        name: 'm_gift',
        component: () => import('@/components/mobile/gift'),
        meta: {
            title: '旧衣服回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/m_mine',
        name: 'm_mine',
        component: () => import('@/components/mobile/mine'),
        meta: {
            title: '旧衣服回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/m_order',
        name: 'm_order',
        component: () => import('@/components/mobile/order'),
        meta: {
            title: '旧衣服回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/m_aboutUs',
        name: 'm_aboutUs',
        component: () => import('@/components/mobile/aboutUs'),
        meta: {
            title: '旧衣服回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/m_account',
        name: 'm_account',
        component: () => import('@/components/mobile/account'),
        meta: {
            title: '旧衣服回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/m_leaveWord',
        name: 'm_leaveWord',
        component: () => import('@/components/mobile/leaveWord'),
        meta: {
            title: '旧衣服回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/m_leaveWordReply',
        name: 'm_leaveWordReply',
        component: () => import('@/components/mobile/leaveWordReply'),
        meta: {
            title: '旧衣服回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/m_customer',
        name: 'm_customer',
        component: () => import('@/components/mobile/customer'),
        meta: {
            title: '旧衣服回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/m_success',
        name: 'm_success',
        component: () => import('@/components/mobile/success'),
        meta: {
            title: '旧衣服回收',
            showFooter: true,
            showHeader: true,
        }
    },

    // 旧书模块
    {
        path: '/b_index',
        name: 'b_index',
        component: () => import('@/components/book/index'),
        meta: {
            title: '旧书回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/b_retrieve',
        name: 'b_retrieve',
        component: () => import('@/components/book/retrieve'),
        meta: {
            title: '旧书回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/b_address',
        name: 'b_address',
        component: () => import('@/components/book/address'),
        meta: {
            title: '旧书回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/b_addAddress',
        name: 'b_addAddress',
        component: () => import('@/components/book/addAddress'),
        meta: {
            title: '旧书回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/b_gift',
        name: 'b_gift',
        component: () => import('@/components/book/gift'),
        meta: {
            title: '旧书回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/b_mine',
        name: 'b_mine',
        component: () => import('@/components/book/mine'),
        meta: {
            title: '旧书回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/b_order',
        name: 'b_order',
        component: () => import('@/components/book/order'),
        meta: {
            title: '旧书回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/b_aboutUs',
        name: 'b_aboutUs',
        component: () => import('@/components/book/aboutUs'),
        meta: {
            title: '旧书回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/b_account',
        name: 'b_account',
        component: () => import('@/components/book/account'),
        meta: {
            title: '旧书回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/b_leaveWord',
        name: 'b_leaveWord',
        component: () => import('@/components/book/leaveWord'),
        meta: {
            title: '旧书回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/b_leaveWordReply',
        name: 'b_leaveWordReply',
        component: () => import('@/components/book/leaveWordReply'),
        meta: {
            title: '旧书回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/b_customer',
        name: 'b_customer',
        component: () => import('@/components/book/customer'),
        meta: {
            title: '旧书回收',
            showFooter: true,
            showHeader: true,
        }
    },
    {
        path: '/b_success',
        name: 'b_success',
        component: () => import('@/components/book/success'),
        meta: {
            title: '旧书回收',
            showFooter: true,
            showHeader: true,
        }
    },
]

const router = createRouter({
    history: routerHistory,
    routes: document.body.clientWidth > 768 ? routes : routesM
})


// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
    next()
    window.scrollTo(0, 0);
    // 顶部标题
    if (to.meta.title) {
        window.document.title = to.meta.title
    } else {
        window.document.title = '绿蜜蜂旧衣服回收'
    }
});

export default router;