
import { createApp } from 'vue'
import App from './App.vue'
import './common/index.css'

const app = createApp(App);

//引入封装Axios请求
import Axios from './utils/http';
app.use(Axios);

import VueRouter from 'vue-router';
app.use(VueRouter);

import router from "./utils/router"
app.use(router);

// 引入element-plus 转译中文 图标
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
app.use(ElementPlus, {
  locale: zhCn,
})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}



app.mount('#app')

